import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
let $ = jQuery;

import 'jquery-ui/ui/widgets/button';
import 'lightbox2';
import '../../global/js/cms.js';

$( document ).ready( function() {
    $( ".hero tr.hero-title td h6" ).each(function() {
        $(this).append( $( "<span />" ).addClass( "hero-toggler" ).text( "pokaż szczegóły" ).bind("click", function() {
            var tbody = $(this).closest("table").find("tbody");
            if(tbody.is(':visible')) {
                $(this).html('pokaż szczegóły');
                tbody.hide();
            } else {
                $(this).html('ukryj szczegóły');
                tbody.show();
            }
        }));
    });
    $('.hero tbody').hide();
    $( ".heroes-base-skills" ).after(
        $( "<span />" ).addClass( "heroes-toggle-all" ).addClass( "hero-hidden" )
            .text('pokaż wszystko').bind('click', function() {
            if($(this).hasClass('hero-hidden')) {
                $(this).removeClass( "hero-hidden" ).addClass( "hero-visible" ).text('ukryj wszystko');
            } else {
                $(this).removeClass( "hero-visible" ).addClass( "hero-hidden" ).text('pokaż wszystko');
            }
            $('.hero tr.hero-title td h6 span').each(function() {
                $(this).click();
            });
        })
    );
    // ukrycie/pokazanie szczegółów bohatera
    /*$( ".hero tr.hero-title td" )
     .toggle(
     function() {
     $( this )
     .removeClass( "hero-hidden" )
     .addClass( "hero-visible" )
     .children( "h6" )
     .children( ".hero-toggler" )
     .empty()
     .text( "ukryj szczegóły" )
     .closest( "table" )
     .find( "tbody" )
     .slideDown( 100 );
     },
     function() {
     $( this )
     .addClass( "hero-hidden" )
     .removeClass( "hero-visible" )
     .children( "h6" )
     .children( ".hero-toggler" )
     .text( "pokaż szczegóły" )
     .empty()
     .closest( "table" )
     .find( "tbody" )
     .slideUp( 10 );
     }
     );*/

    // pokaż/ukryj wszystko
    /*$( ".heroes-base-skills" )
     .after(
     $( "<span />" )
     .addClass( "heroes-toggle-all" )
     )
     .next()
     .bind(
     "click",
     function() {
     if ( $( this ).is( ".heroes-visibled" ) ) {
     $( ".heroes-toggle-all" )
     .removeClass( "heroes-visibled" )
     .addClass( "heroes-hidden" )
     .text( "pokaż wszystkie szczegóły" );
     $( ".hero tr.hero-title td:not( .hero-hidden )" )
     .each( function() {
     $( this ).hide();
     }
     );
     } else {
     $( ".heroes-toggle-all" )
     .addClass( "heroes-visibled" )
     .removeClass( "heroes-hidden" )
     .text( "ukryj wszystkie szczegóły" );
     $( ".hero tr.hero-title td:not( .hero-visible )" )
     .each( function() {
     $( this ).show();
     }
     );
     }
     }
     )
     .each( function() {
     $( this ).triggerHandler( "click" );
     } );
     */
    /*

     .sprite-graal_off{ background-position: 0 0; width: 21px; height: 17px; }
     .sprite-graal_on{ background-position: -22px 0; width: 21px; height: 17px; }
     .sprite-konik{ background-position: -44px 0; width: 26px; height: 42px; }
     .sprite-krol{ background-position: -71px 0; width: 26px; height: 42px; }
     .sprite-krolowa{ background-position: -98px 0; width: 26px; height: 42px; }
     .sprite-pionek{ background-position: -125px 0; width: 26px; height: 42px; }
     .sprite-wieza{ background-position: -152px 0; width: 26px; height: 42px; }

     **/

    var resultCalculator = $('#result-calculator');

    if (resultCalculator.length === 0) {
        return;
    }
    function calculate() {
        var obj = {},
            inputs = $('#result-calculator input');
        var el, type, i;
        for (i = 0; i < inputs.length; i++) {
            el = $(inputs[i]);
            type = el.attr('type');
            if (type === 'checkbox') {
                obj[el.attr('name')] = el.prop('checked');
            }
            else if (type === 'text') {
                obj[el.attr('id')] = parseInt(el.val(), 10);
            }
            else if (type === 'radio' && el.prop('checked')) {
                obj[el.attr('name')] = parseFloat(el.attr('data-value'));
            }
        }
        var days = (obj.weeks - 1) * 7 + (obj.months-1)*28+obj.days;
        var result = 200 -
            Math.floor((days + 10) // days
                /
                (5+obj.towns)) // towns
            + ((obj.Graal|0) * 25) + ((obj.allEnemies|0) * 25);
        result = result * obj.DifficultyLevel;
        $('#end-result span').text(result|0);
    }
    function executeMultiply (arr) {
        return function(e) {
            var that = this;
            for (var i = 0; i < arr.length; i++) {
                arr[i].call(that, e);
            }
        }
    }
    var difficultyLevelPicker = (function(){
        function changeInformationClosure(msg){
            return function(){
                !$(this).prop('checked') || $('h3#difficulty-info span').text(msg);
            };
        }
        var el;
        var ret = $('<div />').attr('id', 'difficulty-picker');

        var list = [
            [
                'łatwy',
                'pionek',
                0.8
            ],
            [
                'normalny',
                'konik',
                1.0
            ],
            [
                'trudny',
                'wieza',
                1.3
            ],
            [
                'ekspert',
                'krol',
                1.6
            ],
            [
                'niemożliwy',
                'krolowa',
                2.0
            ]
        ];
        for (var i = 0; i < list.length; i++) {
            el = list[i];
            ret.append(
                $('<input />')
                    .attr(
                        {
                            type: 'radio',
                            name: 'DifficultyLevel',
                            id: 'diff-level-'+el[1],
                            'data-value': el[2],
                        }
                    )
                    .on('change', executeMultiply([changeInformationClosure(el[0]), calculate]))
                    .prop(
                        'checked', (i === 1)
                    )
                ,
                $('<label />').addClass('diff-picker').attr('for', 'diff-level-'+el[1]).html(
                    '<span class="diff-levels sprite-'+el[1]+'"></span>'
                )

            )
        }
        ret.append('<h3 id="difficulty-info">Poziom trudności: <span>normalny</span></h3>')


        ret.controlgroup();
        return ret;
    })();
    var GraalCheck = (function(){
        var ret = $('<div />').attr('id', 'other-picker');
        ret.append(
            $('<input />')
                .attr(
                    {
                        type: 'checkbox',
                        name: 'Graal',
                        id: 'graal-checkbox'
                    }
                )
                .prop(
                    'checked', false
                )
                .on('change', executeMultiply ([function(){
                    $('#graal-status span').text($(this).prop('checked') ? 'zdobyty' : 'niezdobyty')
                }, calculate]))
            ,
            $('<label />').addClass('graal-switch-box').attr('for', 'graal-checkbox').html(
                '<span class="graal-switch"></span>'
            )
        )
        ret.append('<h3 id="graal-status">Graal: <span>niezdobyty</span></h3>')
        ret.controlgroup();
        return ret;
    })();
    var AllEnemies = (function(){
        var ret = $('<div />').attr('id', 'all-enemies-defeat-picker');
        ret.append(
            $('<input />')
                .attr(
                    {
                        type: 'checkbox',
                        name: 'allEnemies',
                        id: 'all-enemies-checkbox'
                    }
                )
                .prop(
                    'checked', false
                )
                .on('change', executeMultiply([function(){
                    $('#defeat-status span').text($(this).prop('checked') ? 'pokonani' : 'niepokonani')
                }, calculate]))
            ,
            $('<label />').addClass('enemies-switch-box').attr('for', 'all-enemies-checkbox').html(
                '<span class="enemies-switch"></span>'
            )
        )
        ret.append('<h3 id="defeat-status">Wszyscy wrogowie: <span>pokonani</span></h3>')
        ret.controlgroup();
        return ret;
    })();

    var Time = (function(){
        function allowOnlyDigits(e) {
            if (/\D/g.test(this.value))
            {
                var val = this.value.replace(/\D/g, '');
                // Filter non-digits from input value.
                if (val.length === 0) {
                    val = '1';
                }
                this.value = val;
            }
        }
        function maxVal(max, min){
            max = max|0;
            if (typeof max === 'undefined') {
                max = 1000;
            }
            min = min|0;
            if (typeof min === 'undefined') {
                min = 0;
            }
            return function(){
                var val = parseInt(this.value);
                // Filter non-digits from input value.
                if (val > max) {
                    val = max;
                }
                if (val < min) {
                    val = min;
                }
                this.value = val;
            }
        }
        var ret = $('<ul id="time-picker" />');
        ret.append(
            $('<li />').append(
                'Miesiąc: ', $('<input type="text" id="months" value="1"   />').change(executeMultiply([allowOnlyDigits, maxVal(1000, 1), calculate]))
            ),
            $('<li />').append(
                'Tydzień: ', $('<input type="text" id="weeks" value="1"  />').change(executeMultiply([allowOnlyDigits, maxVal(4, 0), calculate]))
            ),
            $('<li />').append(
                'Dzień: ', $('<input type="text" id="days" value="1"   />').change(executeMultiply([allowOnlyDigits,  maxVal(7, 1), calculate]))
            ),
            $('<li />').append(
                'Twoje miasta: ', $('<input type="text" id="towns" value="1"   />').change(executeMultiply([allowOnlyDigits,  maxVal(50, 0), calculate]))
            )
        )
        return ret;
    })();
    resultCalculator.append(
        difficultyLevelPicker
        ,
        GraalCheck
        ,
        AllEnemies,
        Time,
        '<h3 id="end-result">Twój wynik: <span></span>.</h3>'
    );
    calculate();
    resultCalculator.before($('<h1>Kalkulator wyników</h2>'));

} );

$(document).ready(function() {
    $('.img a[href$=jpg], .img a[href$=png], .img a[href$=gif]').each(function() {
        $(this).attr('data-lightbox', 'image');
    });
});
